import { useState, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { LocalCart } from '~/types/cart';

const { persistAtom } = recoilPersist();

const DEFAULT_CART_STATE = {
  count: 0,
  items: {
    lineItems: [],
  },
  timestamp: null, // Add a timestamp to the default state
};

type CartState = {
  count: number;
  items: LocalCart;
  timestamp: string | null; // ISO string for the timestamp
};

export const cartState = atom<CartState>({
  key: 'cart',
  default: DEFAULT_CART_STATE,
  effects_UNSTABLE: [persistAtom],
});

export const useCartSSR = () => {
  const [value, setValue] = useRecoilState(cartState);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      if (value.timestamp) {
        const timeElapsed = Date.now() - new Date(value.timestamp).getTime();
        // 15 minutes in milliseconds
        if (timeElapsed > 15 * 60 * 1000) {
          // Reset the state to default if 15 minutes have passed
          setValue(DEFAULT_CART_STATE);
        }
      } else {
        // Reset to default if there's no timestamp
        setValue(DEFAULT_CART_STATE);
      }
    }, 15000); // Check every 15 seconds

    return () => clearInterval(cleanupInterval); // Clean up interval on component unmount
  }, [value, setValue]);

  useEffect(() => {
    setIsInitial(false);
  }, []);

  const updateCart = (newCartState: Partial<Omit<CartState, 'timestamp'>>) => {
    setValue({
      ...value,
      ...newCartState,
      timestamp: new Date().toISOString(), // Update the timestamp whenever the state changes
    });
  };

  return [isInitial ? DEFAULT_CART_STATE : value, updateCart] as const;
};
